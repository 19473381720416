import React from "react"
import { graphql, Link } from 'gatsby'

import LazyAnimationImage from '../components/lazeeAnimationImage'

import { Card, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/layout"

import Head from "../components/head"

const NotFound = ({data}) => {
    return (
        <LazyAnimationImage>
          <div>
              <Layout>
                <Head title="Page not found" />
                  <Card style={{ width: '70%', margin: `10vh auto`, color: `rgb(203, 0, 24)` }}> {/* 225, 6, 4 */}
                    <Card.Body>
                      <Card.Title
                        style={{fontSize: `3rem`, margin: `1rem`}}
                        >Ooops! 
                        {/* &nbsp;How did you end up here? */}
                      </Card.Title>
                        <Card.Subtitle 
                          style={{fontSize: `2rem`, margin: `1rem`}}
                          className="mb-2">The page you're looking for doesn't exist.
                        </Card.Subtitle>
                      <Card.Text
                      style={{margin: `1rem`}}
                      >
                        Please click the button below.
                      </Card.Text>
                      <Link to="/">
                        <Button className="noPage-button" variant="outline-dark">
                          Vicenza's Crostoli Home
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
              </Layout>
            </div>
        </LazyAnimationImage>
    )
}

export default NotFound

// export const pageQuery = graphql`
//   query { 
//     easterWall: file(relativePath: { eq: "Easter-2021-Background-WhiteWall.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1200, quality: 50) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `